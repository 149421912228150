import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useList } from 'react-use';
import { Badge, Picture, Typography, EmptyComponent, Spinner, Button } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';

import { fetchBookings } from '../../../services/bookings';
import { FilterSection } from './FilterSection';
import { BookingChat } from './BookingChat';
import * as S from './ReceivedBookingsTab.styles';

export const VIEW_MESSAGES_OPTIONS = [
	{ value: 'grouped-by-resource', label: 'Grouped by Resource' },
	{ value: 'individual-messages', label: 'Individual Messages' },
];
export const TABS_OPTIONS = [
	{ value: 'all', label: 'All' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'scheduled', label: 'Scheduled' },
	{ value: 'complete', label: 'Completed' },
];

export const BADGE_STATUS = {
	pending: {
		label: 'Pending',
		color: 'primary',
	},
	scheduled: {
		label: 'Scheduled',
		color: 'neutral',
	},
	draft: {
		label: 'Draft',
		color: 'neutral',
	},
	complete: {
		label: 'Completed',
		color: 'success',
	},
	incomplete: {
		label: 'Incomplete',
		color: 'danger',
	},
	archived: {
		label: 'Archived',
		color: 'neutral',
	},
	accepted: {
		label: 'Accepted',
		color: 'success',
	},
	declined: {
		label: 'Declined',
		color: 'danger',
	},
};

export const BookingsContext = createContext();

export const initialState = {
	viewMessages: VIEW_MESSAGES_OPTIONS[0],
	selectedTab: TABS_OPTIONS[0],
	searchInputValue: '',
	groupedHeaderInfo: null,
	selectedConversation: null,
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_VIEW_MESSAGES':
			return {
				...state,
				viewMessages: action.payload,
				selectedTab: TABS_OPTIONS[0],
			};
		case 'SET_SELECTED_TAB':
			return {
				...state,
				selectedTab: action.payload,
			};
		case 'SET_SEARCH_INPUT_VALUE':
			return {
				...state,
				searchInputValue: action.payload,
			};
		case 'SET_GROUPED_HEADER_INFO':
			return {
				...state,
				groupedHeaderInfo: action.payload,
				searchInputValue: '',
			};
		case 'CLEAR_GROUPED_HEADER_INFO':
			return {
				...state,
				selectedTab: TABS_OPTIONS[0],
				groupedHeaderInfo: null,
				searchInputValue: '',
			};
		case 'SET_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: action.payload,
			};
		case 'CLEAR_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: null,
			};
		default:
			return state;
	}
}

const IndividualItem = ({ id, business, resource, lastMessage, status, creatorBusiness, creator }) => {
	const {
		state: { selectedConversation },
		dispatch,
	} = useContext(BookingsContext);

	return (
		<S.ListItem key={id} onClick={() => dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { id, business, resource, status, creatorBusiness, creator } })} className={selectedConversation != null && selectedConversation.id === id ? 'is-selected' : ''}>
			<div className="individual-card__avatar-wrapper">
				<Picture className="individual-card__avatar" src={Object.keys(creatorBusiness)?.length > 0 ? creatorBusiness.logoObj[200] : creator.profileImageObj[200]} aspectRatio="1/1" />
			</div>
			<div className="individual-card__info-wrapper">
				<Typography className="individual-card__name" tag="h5" weight="bold">
					{Object.keys(creatorBusiness)?.length > 0 ? creatorBusiness.name : `${creator.firstName} ${creator.lastName}`}
				</Typography>
				<Typography className="individual-card__category" tag="p" variation="3">
					{lastMessage ? `${formatToRelativeTime(new Date(lastMessage.date.iso))} • ${resource.title}` : resource.title}
				</Typography>
				{lastMessage && (
					<Typography className="individual-card__message" tag="p" variation="2">
						{lastMessage.message}
					</Typography>
				)}
			</div>
			<div className="individual-card__badge-wrapper">
				<Badge small="small" type={BADGE_STATUS[status].color} variant="outlined">
					{BADGE_STATUS[status].label}
				</Badge>
			</div>
		</S.ListItem>
	);
};
IndividualItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	business: PropTypes.object.isRequired,
	resource: PropTypes.object,
	lastMessage: PropTypes.object,
	status: PropTypes.string.isRequired,
	creatorBusiness: PropTypes.object,
	creator: PropTypes.object,
};

const GroupedItem = ({ id, coverImageObj, category, title, responses, creator }) => {
	const { dispatch } = useContext(BookingsContext);

	const handleClick = () => {
		dispatch({ type: 'SET_GROUPED_HEADER_INFO', payload: { title, responses, creator } });
	};

	return (
		<S.GroupedListItem key={id} onClick={handleClick}>
			<div className="grouped-card__thumbnail-wrapper">
				<Picture pictureClassName="grouped-card__thumbnail" src={coverImageObj?.[200]} aspectRatio="3/2" />
			</div>
			<div className="grouped-card__info-wrapper">
				<Typography className="grouped-card__category" tag="p" variation="3">
					{category}
				</Typography>
				<Typography className="grouped-card__name" tag="h5" weight="bold">
					{title}
				</Typography>
				<div className="grouped-card__count-wrapper">
					<FontAwesomeIcon icon={creator.name ? ['fal', 'building'] : ['fal', 'chalkboard-teacher']} />
					<Typography className="grouped-card__count-text" tag="p" variation="2">
						{creator.name ? `${creator.name} (Business)` : `${creator.firstName} ${creator.lastName} (Teacher)`}
					</Typography>
				</div>
			</div>
		</S.GroupedListItem>
	);
};
GroupedItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	coverImageObj: PropTypes.shape(),
	category: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	creator: PropTypes.object.isRequired,
	responses: PropTypes.arrayOf(PropTypes.shape({ ...IndividualItem.propTypes })).isRequired,
};

export const ReceivedBookingsTab = ({}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [pageStatus, setPageStatus] = useState('idle');
	const { viewMessages, selectedTab, searchInputValue, selectedConversation, groupedHeaderInfo } = state;
	const [individualList, { set: setIndList, filter: filterInd }] = useList([]);
	const [groupedList, { set: setGrpList, filter: filterGrp }] = useList([]);
	const [currentSelectedGroupedList, { set: setSlctdGrp, filter: filterSelectedGrp }] = useList([]);

	const requestsArray = useRef([]);
	const responsesArray = useRef([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const handleListFilter = (filterMethod, resetMethod) => {
		resetMethod();
		if (searchInputValue && selectedTab.value === 'all') {
			filterMethod((item) => item.resource.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()));
		} else if (searchInputValue && selectedTab.value !== 'all') {
			filterMethod((item) => item.resource.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()) && item.status === selectedTab.value);
		} else if (searchInputValue === '' && selectedTab.value !== 'all') {
			filterMethod((item) => item.status === selectedTab.value);
		}
	};

	const handleFetchData = async () => {
		setPageStatus('loading');

		// Fetch data for value
		if (viewMessages.value === 'grouped-by-resource') {
			const {
				data: { result },
			} = await fetchBookings({
				page: 0,
				filterOptions: { inbound: true, outbound: false },
			});

			const conversations = Object.values(
				result.bookings.reduce((acc, booking) => {
					const { id } = booking.resource;
					if (!acc[id]) {
						acc[id] = {
							id,
							resource: booking.resource,
							creator: Object.keys(booking.creatorBusiness).length > 0 ? booking.creatorBusiness : booking.creator,
							responses: [],
						};
					}
					acc[id].responses = acc[id].responses.concat(booking);
					return acc;
				}, {})
			);

			setPageNumber(1);
			setTotalPages(result.totalPages);

			responsesArray.current = result.bookings;
			requestsArray.current = conversations;
			setGrpList(conversations);
		} else {
			const {
				data: { result },
			} = await fetchBookings({
				page: 0,
				filterOptions: { inbound: true, outbound: false },
			});

			setPageNumber(1);
			setTotalPages(result.totalPages);

			responsesArray.current = result.bookings;
			setIndList(result.bookings);
		}
		setPageStatus('success');
	};

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchBookings({
					page: pageNumber,
					filterOptions: { inbound: true, outbound: false },
				});
				if (viewMessages.value === 'grouped-by-resource') {
					const conversations = Object.values(
						[...responsesArray.current, ...result.bookings].reduce((acc, booking) => {
							const { id } = booking.resource;
							if (!acc[id]) {
								acc[id] = {
									id,
									resource: booking.resource,
									creator: Object.keys(booking.creatorBusiness).length > 0 ? booking.creatorBusiness : booking.creator,
									responses: [],
								};
							}
							acc[id].responses = acc[id].responses.concat(booking);
							return acc;
						}, {})
					);

					responsesArray.current = [...responsesArray.current, ...result.bookings];
					requestsArray.current = conversations;
					setGrpList(conversations);
				} else {
					responsesArray.current = [...responsesArray.current, ...result.bookings];
					setIndList(responsesArray.current);
				}
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	const filterData = () => {
		// Update filter state
		if (!!groupedHeaderInfo) {
			handleListFilter(filterSelectedGrp, () =>
				setSlctdGrp(
					groupedHeaderInfo.responses.map((response) => {
						return {
							...response,
							request: {
								...response.request,
								title: groupedHeaderInfo.title,
								status: groupedHeaderInfo.status,
							},
						};
					})
				)
			);
		} else {
			if (viewMessages.value === 'grouped-by-resource') {
				handleListFilter(filterGrp, () => setGrpList(requestsArray.current));
			} else {
				handleListFilter(filterInd, () => setIndList(responsesArray.current));
			}
		}
	};

	useEffect(async () => {
		// Fetch data
		await handleFetchData();

		// Update filter state
		filterData();
	}, [viewMessages]);

	useEffect(async () => {
		// Update filter state
		filterData();
	}, [selectedTab, searchInputValue, groupedHeaderInfo]);

	return (
		<BookingsContext.Provider value={{ state, dispatch }}>
			<S.Wrapper someChatIsSelected={selectedConversation}>
				<S.ContentWrapper>
					<FilterSection />
					<S.RelativeWrapper>
						<S.ListWrapper>
							{!!groupedHeaderInfo &&
								(currentSelectedGroupedList.length > 0 ? (
									currentSelectedGroupedList.map(({ id, business, resource, lastMessage, status, creatorBusiness, creator }) => (
										<IndividualItem key={id} id={id} business={business} resource={resource} lastMessage={lastMessage} status={status} creatorBusiness={creatorBusiness} creator={creator} />
									))
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
								))}
							{!groupedHeaderInfo &&
								viewMessages.value === 'grouped-by-resource' &&
								(groupedList.length > 0 ? (
									groupedList.map(({ id, resource, responses, creator }) => <GroupedItem key={id} id={id} creator={creator} coverImageObj={resource.coverImageObj} category={resource.category} title={resource.title} responses={responses} status={resource.status} />)
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available bookings" message="Check back later to view available messages for bookings." icon={['fal', 'comment']} />
								))}
							{viewMessages.value === 'individual-messages' &&
								(individualList.length > 0 ? (
									individualList.map(({ id, business, resource, lastMessage, status, creatorBusiness, creator }) => (
										<IndividualItem key={id} id={id} business={business} resource={resource} lastMessage={lastMessage} status={status} creatorBusiness={creatorBusiness} creator={creator} />
									))
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
								))}
						</S.ListWrapper>
					</S.RelativeWrapper>
					{totalPages > pageNumber + 1 && (
						<S.LoadMoreWrapper>
							<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
								<Typography variation="button-medium" weight="bold">
									{loadingMore ? 'Loading...' : 'Load More'}
								</Typography>
							</Button>
						</S.LoadMoreWrapper>
					)}
				</S.ContentWrapper>
				{selectedConversation && (
					<S.ChatWrapper>
						<div className="relative-wrapper">
							<BookingChat />
						</div>
					</S.ChatWrapper>
				)}
			</S.Wrapper>
		</BookingsContext.Provider>
	);
};
