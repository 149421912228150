import React, { useContext, useRef, useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { buildAddressString } from '../../../../utils/location-methods';
import { Button, Card, DetailedCheckbox, IconButton, Map, RangeInput, Select, TextArea, Typography } from '../../../../components';
import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { createRequest } from '../../../../services/requests/createRequestService';
import { required } from '../../../../utils/form-default-errors';
import { errorHandler } from '../../../../services/authService';
import { LOCATION_TYPES, ROLES } from '../../../../../constants/general.constants';
import * as S from './LocationCard.styles';

const LOCATION_TYPES_WITHOUT_IN_PERSON = LOCATION_TYPES.filter(({ id }) => id !== 'inperson');

const LocationEditModal = forwardRef((_, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		watch,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	const user = useSelector((state) => state.user.value);
	const schoolOptions = user?.schools ? [...user?.schools] : [];

	const selectedSchool = schoolOptions.find(({ id }) => id === watch('schoolLocationId'));

	const locationTypes = watch('locationTypes');

	const handleOpen = () => {
		setValue('locationTypes', request?.locationTypes);
		setValue('virtualLocationNotes', request?.virtualLocationNotes);
		setValue('schoolLocationId', schoolOptions?.find(({ id }) => id === request?.schoolLocation?.id)?.id);
		setValue('studentLocationNotes', request?.studentLocationNotes);
		setValue('worksiteMaxTravelDistance', request?.worksiteMaxTravelDistance);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const { virtualLocationNotes, studentLocationNotes, worksiteMaxTravelDistance, schoolLocationId } = data;
		const newValues = {
			locationTypes: data?.locationTypes,
			virtualLocationNotes: data?.locationTypes?.includes('virtual') ? virtualLocationNotes : null,
			schoolLocationId: data?.locationTypes?.includes('student') ? schoolLocationId : null,
			studentLocationNotes: data?.locationTypes?.includes('student') ? studentLocationNotes : null,
			worksiteMaxTravelDistance: data?.locationTypes?.includes('worksite') ? worksiteMaxTravelDistance : null,
		};
		const newRequest = formatPayloadBeforeUpdate(request, { ...newValues });

		const {
			data: { result },
		} = await createRequest(newRequest);
		dispatch({ type: 'setRequest', payload: result.request });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Locations
					</Typography>
					<Typography tag="p" center>
						Please edit the locations below to update the request.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<div>
						<Typography tag="h3" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p" variation="2">
							Select all locations you want to have this resource.
						</Typography>
					</div>
					<S.LocationStepWrapper>
						{LOCATION_TYPES_WITHOUT_IN_PERSON.map(({ id, name, description, icon }) => (
							<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
						))}
						{!!errors?.locationTypes && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors?.locationTypes?.message}
								</Typography>
							</div>
						)}
					</S.LocationStepWrapper>
					<S.SelectedLocations>
						{!locationTypes ||
							(locationTypes.length >= 1 && (
								<>
									<div>
										<Typography tag="h3" weight="bold">
											Location Details
										</Typography>
										<Typography tag="p" variation="2">
											Enter in more information about your locations.
										</Typography>
									</div>
									{locationTypes?.includes('virtual') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Virtual
												</Typography>
												<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
											</div>
										</div>
									)}
									{locationTypes?.includes('worksite') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Worksite Location
												</Typography>
												<Controller
													name="worksiteMaxTravelDistance"
													control={control}
													rules={{ required: required('Maximum Travel Distance') }}
													render={({ field: { onChange, value } }) => <RangeInput error={errors?.worksiteMaxTravelDistance} label="Maximum Travel Distance" id="worksite-max-travel-distance" unit="mi" value={value} onChange={onChange} />}
												/>
											</div>
										</div>
									)}
									{locationTypes?.includes('student') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Student Location
												</Typography>
												<Controller
													name="schoolLocationId"
													control={control}
													rules={{ required: required('Student Location') }}
													render={({ field: { onChange, value } }) => (
														<Select
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
															label="Student Location"
															placeholder="Select a school..."
															id="student-location-school"
															error={errors?.schoolLocationId}
															options={schoolOptions}
															value={schoolOptions.find((c) => c.id === value) || ''}
															onChange={(val) => onChange(val.id)}
														/>
													)}
												/>
												{selectedSchool && (
													<div className="step__information-card">
														<div className="icon-wrapper">
															<FontAwesomeIcon icon="school" />
														</div>
														<div className="info-wrapper">
															<Typography tag="h5" weight="bold">
																{selectedSchool?.name}
															</Typography>
															<div className="info-line">
																<FontAwesomeIcon icon={['fal', 'map-marker']} />
																<Typography tag="p" variation="2">
																	{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
																</Typography>
															</div>
															<div className="info-line">
																<FontAwesomeIcon icon={['fal', 'landmark']} />
																<Typography tag="p" variation="2">
																	{selectedSchool?.address.city}
																</Typography>
															</div>
															<div className="info-line">
																<FontAwesomeIcon icon={['fal', 'school']} />
																<Typography tag="p" variation="2">
																	{selectedSchool?.schoolCode}
																</Typography>
															</div>
														</div>
													</div>
												)}

												<TextArea id="studentLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.studentLocationNotes} {...register('studentLocationNotes')} />
											</div>
										</div>
									)}
								</>
							))}
					</S.SelectedLocations>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});

LocationEditModal.displayName = 'LocationEditModal';

export const LocationCard = ({ className, role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);

	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleEditClick = () => modalRef.current.open();

	const isOnlyVirtual = useMemo(() => {
		return request.locationTypes.includes('virtual') && request.locationTypes.length === 1;
	}, [request]);

	return (
		<Card
			title="Location"
			className={className}
			transparentHeaderBorder
			actions={(role != null && request.creator != null && request.creator.id === user?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				<div className="location-info">
					{request.locationTypes.includes('virtual') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'camera']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Virtual
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Meet your host virtually.
									</Typography>
								</div>
							</div>
						</div>
					)}
					{request.locationTypes.includes('student') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'users']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Student Location
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Have the business travel to you.
									</Typography>
									<div className="location-info__detail-wrapper">
										<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
											Student Location
										</Typography>
										<div key={request.schoolLocation.id}>
											<Typography tag="p">{request.schoolLocation.name}</Typography>
											<Typography tag="p" variation="2" className="location-info__detail-wrapper__address">
												{buildAddressString(request.schoolLocation.address)}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{request.locationTypes.includes('worksite') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'building']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Worksite Location
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Travel to the business location.
									</Typography>
									<div className="location-info__detail-wrapper">
										<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
											Maximum Travel Distance
										</Typography>
										<Typography tag="p">{request.worksiteMaxTravelDistance > 1 ? `${request.worksiteMaxTravelDistance} miles` : '1 mile'}</Typography>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{!isOnlyVirtual && (
					<div className="location-map">
						<div className="location-map__wrapper">
							<Map withMarkerIndex={true} role={role} enableGeolocationFallback={!role} markers={[request]} entityType="request" />
						</div>
					</div>
				)}
			</S.Wrapper>
			<LocationEditModal ref={modalRef} />
		</Card>
	);
};

LocationCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
