import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { format } from 'date-fns';

import { Card, Calendar, Typography, Button, IconButton, Radio, TextArea, DatePicker } from '../../../../components';
import * as S from './AvailabilityCard.styles';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { DAYS_OF_WEEK, ROLES } from '../../../../../constants/general.constants';
import { DirectoryResourceDetailsContext } from '../../DirectoryResourceDetails';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { errorHandler } from '../../../../services/authService';
import { createUpdateResource } from '../../../../services/resources';
import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { required } from '../../../../utils/form-default-errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AvailabilityEditModal = forwardRef((_, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(DirectoryResourceDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		watch,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	const isOngoing = watch('isOngoing');

	const handleOpen = () => {
		setValue('startDate', resource.availabilityStartDate ? moment(resource.availabilityStartDate, 'MM-DD-YYYY', true).toDate() : null);
		setValue('isOngoing', resource.availabilityIsOngoing ? 'yes' : 'no');
		setValue('endDate', resource.availabilityEndDate ? moment(resource.availabilityEndDate, 'MM-DD-YYYY', true).toDate() : null);
		setValue('days', resource.availabilityDays);
		setValue('availabilityNotes', resource.availabilityNotes);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const { startDate, endDate, days, availabilityNotes } = data;

		const newValues = {
			startDate: startDate ? format(startDate, 'MM-dd-yyyy') : null,
			endDate: endDate ? format(endDate, 'MM-dd-yyyy') : null,
			isOngoing: isOngoing === 'yes' ? true : false,
			days: days ? days : [],
			availabilityNotes: availabilityNotes ? availabilityNotes : null,
		};
		const newResource = formatPayloadBeforeUpdate(resource, { ...newValues });

		const {
			data: { result },
		} = await createUpdateResource(newResource);
		dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="availability-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Availability
					</Typography>
					<Typography tag="p" center>
						Please edit your availability for this resource.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<Controller control={control} name="startDate" rules={{ required: required('Start Date') }} render={({ field: { value, onChange } }) => <DatePicker label="Start Date" error={errors?.startDate} selected={value} onChange={onChange} />} />
					<S.RadioOptionsWrapper>
						<Typography className={`field-label${!!errors.isOngoing ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
							Is this ongoing?
						</Typography>

						<div className="radio-field">
							<Radio
								id="isOngoing1"
								value="yes"
								{...register('isOngoing', {
									required: required('Is this ongoing'),
								})}
							/>
							<label htmlFor="isOngoing1">Yes, it is ongoing</label>
						</div>
						<div className="radio-field">
							<Radio
								id="isOngoing2"
								value="no"
								{...register('isOngoing', {
									required: required('Is this ongoing'),
								})}
							/>
							<label htmlFor="isOngoing2">No, there is an end date</label>
						</div>

						{!!errors.isOngoing && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.isOngoing.message}
								</Typography>
							</div>
						)}
					</S.RadioOptionsWrapper>

					{isOngoing === 'no' && <Controller control={control} name="endDate" rules={{ required: required('End Date') }} render={({ field: { value, onChange } }) => <DatePicker label="End Date" error={errors?.endDate} selected={value} onChange={onChange} />} />}

					<S.DaysSelector>
						<Typography className={`field-label${!!errors.days ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
							Days
						</Typography>

						<div className="days-wrapper">
							{DAYS_OF_WEEK.map((value) => (
								<div key={value} className="day-wrapper">
									<input type="checkbox" id={`id-${value}`} value={value} {...register('days', { required: required('Days') })} />
									<label htmlFor={`id-${value}`}>{value}</label>
								</div>
							))}
						</div>

						{!!errors.days && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.days.message}
								</Typography>
							</div>
						)}
					</S.DaysSelector>

					<TextArea label="Availability Notes" id="availability-notes" placeholder="Write a message..." {...register('availabilityNotes')} />
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
AvailabilityEditModal.displayName = 'AvailabilityEditModal';

const DateAndTimeEditModal = forwardRef((_, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(DirectoryResourceDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		watch,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	const isMultiple = watch('isMultiple');

	const { fields, append, remove } = useFieldArray({ control, name: 'timeFrames' });

	const handleAddLocation = () => {
		append({});
	};

	const handleRemoveLocation = (index) => {
		remove(index);
	};

	const handleOpen = () => {
		if (resource?.timeFrames?.length > 1) {
			setValue('isMultiple', 'yes');
			setValue(
				'timeFrames',
				resource.timeFrames.map((timeFrame) => ({
					startDate: moment(timeFrame.startDate, 'MM-DD-YYYY', true).toDate(),
					startTime: moment(timeFrame.startDate + ' ' + timeFrame.startTime, 'MM-DD-YYYY H:mm', true).toDate(),
					endDate: moment(timeFrame.endDate, 'MM-DD-YYYY', true).toDate(),
					endTime: moment(timeFrame.endDate + ' ' + timeFrame.endTime, 'MM-DD-YYYY H:mm', true).toDate(),
					notes: timeFrame.notes,
				}))
			);
		} else {
			setValue('isMultiple', 'no');
			setValue('startDate', resource?.timeFrames?.[0]?.startDate ? moment(resource?.timeFrames?.[0]?.startDate, 'MM-DD-YYYY', true).toDate() : null);
			setValue('startTime', resource?.timeFrames?.[0]?.startTime ? moment(resource?.timeFrames?.[0]?.startDate + ' ' + resource?.timeFrames?.[0]?.startTime, 'MM-DD-YYYY H:mm', true).toDate() : null);
			setValue('endDate', resource?.timeFrames?.[0]?.endDate ? moment(resource?.timeFrames?.[0]?.endDate, 'MM-DD-YYYY', true).toDate() : null);
			setValue('endTime', resource?.timeFrames?.[0]?.endTime ? moment(resource?.timeFrames?.[0]?.endDate + ' ' + resource?.timeFrames?.[0]?.endTime, 'MM-DD-YYYY H:mm', true).toDate() : null);
			setValue('timeFrameNotes', resource?.timeFrames?.[0]?.notes);
		}

		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const newValues = {};
		if (data.isMultiple === 'yes') {
			newValues.timeFrames = data.timeFrames.map((timeFrame) => {
				return {
					...timeFrame,
					startDate: format(timeFrame.startDate, 'MM-dd-yyyy'),
					endDate: format(timeFrame.endDate, 'MM-dd-yyyy'),
					startTime: format(timeFrame.startTime, 'h:mm a'),
					endTime: format(timeFrame.endTime, 'h:mm a'),
					notes: timeFrame.notes,
				};
			});
		} else {
			newValues.timeFrames = [
				{
					startDate: data.startDate ? format(data.startDate, 'MM-dd-yyyy') : null,
					endDate: data.endDate ? format(data.endDate, 'MM-dd-yyyy') : null,
					startTime: data.startTime ? format(data.startTime, 'h:mm a') : null,
					endTime: data.endTime ? format(data.endTime, 'h:mm a') : null,
					notes: data.timeFrameNotes,
				},
			];
		}
		const newResource = formatPayloadBeforeUpdate(resource, { ...newValues });

		const {
			data: { result },
		} = await createUpdateResource(newResource);
		dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="availability-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Availability
					</Typography>
					<Typography tag="p" center>
						Please edit your availability for this resource.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<Typography tag="h3" weight="bold">
						Event Duration
					</Typography>

					<S.RadioOptionsWrapper>
						<Typography className={`field-label${!!errors.isMultiple ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
							Are there multiple time frames for this event?
						</Typography>

						<div className="radio-field">
							<Radio
								id="isMultiple1"
								value="yes"
								{...register('isMultiple', {
									required: required('Is this multiple'),
								})}
							/>
							<label htmlFor="isMultiple1">Yes, it is happening in multiple different time frames</label>
						</div>
						<div className="radio-field">
							<Radio
								id="isMultiple2"
								value="no"
								{...register('isMultiple', {
									required: required('Is this multiple'),
								})}
							/>
							<label htmlFor="isMultiple2">No, there is only one time frame for the event</label>
						</div>

						{!!errors.isMultiple && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.isMultiple.message}
								</Typography>
							</div>
						)}
					</S.RadioOptionsWrapper>

					{isMultiple === 'no' ? (
						<>
							<Typography tag="h3" weight="bold">
								Time Frame
							</Typography>

							<div className="two-col">
								<Controller control={control} name="startDate" rules={{ required: required('Start Date') }} render={({ field: { value, onChange } }) => <DatePicker label="Start Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.startDate} />} />
								<Controller
									control={control}
									name="startTime"
									rules={{ required: required('Start Time') }}
									render={({ field: { value, onChange } }) => <DatePicker label="Start Time" selected={value} onChange={onChange} error={errors?.startTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />}
								/>
							</div>

							<div className="two-col">
								<Controller control={control} name="endDate" rules={{ required: required('End Date') }} render={({ field: { value, onChange } }) => <DatePicker label="End Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.endDate} />} />
								<Controller
									control={control}
									name="endTime"
									rules={{ required: required('End Time') }}
									render={({ field: { value, onChange } }) => <DatePicker label="End Time" selected={value} onChange={onChange} error={errors?.endTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />}
								/>
							</div>

							<TextArea label="Time Frame Notes" id="time-frame-notes" placeholder="Write a message..." {...register('timeFrameNotes')} />
						</>
					) : (
						<>
							{fields.map(({ id }, index) => (
								<S.LocationItem key={id}>
									<Typography tag="h5" weight="bold">
										Time Frame {index + 1}
									</Typography>
									<S.FieldsWrapper>
										<div className="two-col">
											<Controller
												control={control}
												name={`timeFrames.${index}.startDate`}
												rules={{ required: required('Start Date') }}
												render={({ field: { value, onChange } }) => <DatePicker label="Start Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.startDate} />}
											/>
											<Controller
												control={control}
												name={`timeFrames.${index}.startTime`}
												rules={{ required: required('Start Time') }}
												render={({ field: { value, onChange } }) => (
													<DatePicker label="Start Time" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.startTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />
												)}
											/>
										</div>
										<div className="two-col">
											<Controller
												control={control}
												name={`timeFrames.${index}.endDate`}
												rules={{ required: required('End Date') }}
												render={({ field: { value, onChange } }) => <DatePicker label="End Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.endDate} />}
											/>
											<Controller
												control={control}
												name={`timeFrames.${index}.endTime`}
												rules={{ required: required('End Time') }}
												render={({ field: { value, onChange } }) => (
													<DatePicker label="End Time" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.endTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />
												)}
											/>
										</div>
										<TextArea
											label="Time Frame Notes"
											id={`${index}-time-frame-notes`}
											error={errors?.timeFrames?.[index]?.notes}
											{...register(`timeFrames.${index}.notes`, {
												required: required('Time Frame Notes'),
											})}
										/>
									</S.FieldsWrapper>
									{fields.length > 1 && (
										<Button className="location-remove" variant="text" type="button" onClick={() => handleRemoveLocation(index)}>
											<Typography variation="button-medium" weight="bold">
												Remove
											</Typography>
										</Button>
									)}
								</S.LocationItem>
							))}
							<S.AddWrapper>
								<Button variant="text" type="button" onClick={handleAddLocation}>
									<FontAwesomeIcon icon={['fal', 'plus']} />
									<Typography variation="button-medium" weight="bold">
										Add Location
									</Typography>
								</Button>
							</S.AddWrapper>
						</>
					)}
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
DateAndTimeEditModal.displayName = 'DateAndTimeEditModal';

export const AvailabilityCard = ({ className, role }) => {
	const {
		state: { resource },
	} = useContext(DirectoryResourceDetailsContext);
	const user = useSelector((state) => state.user.value);
	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	return (
		<Card
			title={resource.type === 'event' ? 'Date and Time' : 'Availability'}
			className={className}
			transparentHeaderBorder
			actions={(role != null && resource.business != null && resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				{resource.type === 'event' ? (
					<>
						{resource.timeFrames.map((timeFrame, index) => (
							<div key={index}>
								<div className="availability-info">
									<div className="availability-info__element a1">
										<Typography className="availability-info__title" tag="h6" weight="semibold">
											Date
										</Typography>
										<Typography className="availability-info__text" tag="p">
											{timeFrame.displayDate}
										</Typography>
									</div>
									<div className="availability-info__element a2">
										<Typography className="availability-info__title" tag="h6" weight="semibold">
											Time
										</Typography>
										<Typography className="availability-info__text" tag="p">
											{timeFrame.displayTime}
										</Typography>
									</div>
								</div>
							</div>
						))}
						<Calendar
							allDates={resource.availablilityDates.map((date) => moment(date, 'MM-DD-YYYY', true).toDate().toDateString())}
							value={resource.availablilityDates.length > 0 ? [moment(resource.availablilityDates[0], 'MM-DD-YYYY', true).toDate(), moment(resource.availablilityDates[resource.availablilityDates.length - 1], 'MM-DD-YYYY', true).toDate()] : undefined}
						/>
					</>
				) : (
					<Calendar
						defaultActiveStartDate={new Date()}
						allDates={resource.availablilityDates.map((date) => moment(date, 'MM-DD-YYYY', true).toDate().toDateString())}
						value={resource.availablilityDates.length > 0 ? [moment(resource.availablilityDates[0], 'MM-DD-YYYY', true).toDate(), moment(resource.availablilityDates[resource.availablilityDates.length - 1], 'MM-DD-YYYY', true).toDate()] : undefined}
					/>
				)}
			</S.Wrapper>
			{resource?.type === 'event' ? <DateAndTimeEditModal ref={modalRef} /> : <AvailabilityEditModal ref={modalRef} />}
		</Card>
	);
};

AvailabilityCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
